import React, { useState } from 'react';
import './Home.css';
import background from './background.png';
import logo from './logo.png';
import computer from './computer.png';
import graph from './graph.png';
import emailIcon from './email.png';

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="home-container" style={{ backgroundImage: `url(${background})` }}>
      <div className="header">
        <img src={logo} className="App-logo" alt="App Logo" />
      </div>
      <div className="head-container">
        <p className="head-text">Stock Alerts for <strong>Your Financial Freedom.</strong></p>
        <img src={computer} className="computer" alt="Computer" />
      </div>
      <br />
      <br />
      <div className="discover">
        <h2>Discover Daily Top Stock & ETF Picks</h2>
        <div>
          <p>ASTAI is for educational purposes only.</p>
          <button className="disclaimer-button" onClick={openModal}><strong>Click here to see full disclaimer</strong></button>
        </div>
        <img src={graph} className="graph" alt="Graph" />
      </div>
      <br />
      <br />
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>Disclaimer</h2>
            <p style={{ fontSize: '20px' }}>
              The information presented is for educational purposes only and should not be considered financial advice.
              It is recommended to consult with a qualified financial advisor before making any investment decisions.
              Past performance is not necessarily indicative of future results. Users are responsible for their own investment
              decisions and any associated risks.
            </p>
          </div>
        </div>
      )}
      <div className="line"></div>
      <div className="connect-container">
        <h2>Connect with us</h2>
        <div className="email-container">
          <img src={emailIcon} className="email" alt="Email Icon" />
          <p style={{ marginTop: '-5px', marginLeft: '5px' }}>info@astaifinance.com</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
